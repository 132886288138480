import React from 'react'

function Hotelsdash() {
  return (
    <div>
    <main id="main" className="main">
     <h3 className='display-5 fw-semibold'>Hotels</h3>
    </main>
 </div>
  )
}

export default Hotelsdash
