import React from 'react'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import Hotelsdash from '../components/Hotelsdash'

function Hotels() {
  return (
    <div>
      <Header/>
      <Sidebar/>
      <Hotelsdash/>
      <Footer/>
    </div>
  )
}

export default Hotels
