import React from 'react'

function Login() {
  return (
    <div>
   <main className='bg-black'>
  <div className="container">
    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex justify-content-center py-4">
              <a href="index.html" className="logo d-flex align-items-center w-auto">
                <img src="./assets/img/logo.png" alt />
                <span className="d-none d-lg-block text-primary">Pak Qurtuba</span>
              </a>
            </div>{/* End Logo */}
            <div className="card mb-3 rounded-3 p-4 bg-dark">
              <div className="card-body">
                <div className="pt-4 pb-2">
                  <h5 className="card-title text-center pb-0 fs-4 text-white">Login to Your Account</h5>
                </div>
                <form className="row g-3 needs-validation" noValidate>
                  <div className="col-12">
                    <label htmlFor="yourUsername" className="form-label text-white">User Name</label>
                    <div className="input-group has-validation">
                      <input type="text" name="username" className="form-control" id="yourUsername" required placeholder='Enter User Name'/>
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="yourPassword" className="form-label text-white">Password</label>
                    <input type="password" name="password" className="form-control" id="yourPassword" required placeholder='Enter Password'/>
                    <div className="invalid-feedback">Please enter your password!</div>
                  </div>
                  <div className="col-12">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="remember" defaultValue="true" id="rememberMe" />
                      <label className="form-check-label text-primary" htmlFor="rememberMe">Remember me</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-danger w-100" type="submit">Login</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="credits">
            <span className='text-white'>Designed by</span> <a href="https://bootstrapmade.com/">Ammar Sami</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>{/* End #main */}

    </div>
  )
}

export default Login
