import React from 'react'
import { Link } from 'react-router-dom'

function Sidebar() {
  return (
    <div>
     {/* ======= Sidebar ======= */}
<aside id="sidebar" className="sidebar">
  <ul className="sidebar-nav" id="sidebar-nav">
    <li className="nav-item">
      <a className="nav-link " href="/adminmain">
        <i className="bi bi-grid" />
        <span>Dashboard</span>
      </a>
    </li>{/* End Dashboard Nav */}
    {/* <li className="nav-item">
      <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
        <i className="bi bi-menu-button-wide" /><span>Components</span><i className="bi bi-chevron-down ms-auto" />
      </a>
      <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
        <li>
          <a href="components-alerts.html">
            <i className="bi bi-circle" /><span>Alerts</span>
          </a>
        </li>
        <li>
          <a href="components-accordion.html">
            <i className="bi bi-circle" /><span>Accordion</span>
          </a>
        </li>
        <li>
          <a href="components-badges.html">
            <i className="bi bi-circle" /><span>Badges</span>
          </a>
        </li>
        <li>
          <a href="components-breadcrumbs.html">
            <i className="bi bi-circle" /><span>Breadcrumbs</span>
          </a>
        </li>
        <li>
          <a href="components-buttons.html">
            <i className="bi bi-circle" /><span>Buttons</span>
          </a>
        </li>
        <li>
          <a href="components-cards.html">
            <i className="bi bi-circle" /><span>Cards</span>
          </a>
        </li>
        <li>
          <a href="components-carousel.html">
            <i className="bi bi-circle" /><span>Carousel</span>
          </a>
        </li>
      </ul>
    </li> */}
    <li className="nav-item">
      <a className="nav-link " href="/adminpackage">
      <i class='bx bxs-package' ></i>
        <span>Packages</span>
      </a>
    </li>{/* End Dashboard Nav */}
    <li className="nav-item">
      <a className="nav-link " href="/adminhotels">
      <i class='bx bxs-hotel'></i>
        <span>Hotels</span>
      </a>
    </li>{/* End Dashboard Nav */}
    <li className="nav-item">
      <a className="nav-link " href="/adminflight">
      <i class='bx bxs-plane-alt' ></i>
        <span>Flight Groups</span>
      </a>
    </li>{/* End Dashboard Nav */}
  </ul>
</aside>{/* End Sidebar*/}


    </div>
  )
}

export default Sidebar
