import React from 'react'
import { BrowserRouter as Router,Route,Routes } from 'react-router-dom'
import Main from './pages/Main'
import Error from './pages/Error'
import Package from './pages/Package'
import Hotels from './pages/Hotels'
import Flight from './pages/Flight'
import Login from './pages/Login'
function App() {
  return (
    <>
   <Router>
    <Routes>
    <Route path="/" element={<Login/>}/>
      <Route path="/adminmain" element={<Main/>}/>
      <Route path="/adminpackage" element={<Package/>}/>
      <Route path="/adminhotels" element={<Hotels/>}/>
      <Route path="/adminflight" element={<Flight/>}/>
      <Route path="*" element={<Error/>}/>

    </Routes>
   </Router>
    </>
  )
}

export default App
