import React from 'react'
import { Link } from 'react-router-dom'

function Error() {
  return (
    <>
  <main>
  <div className="container">
    <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
      <h1>404</h1>
      <h2>The page you are looking for doesn't exist.</h2>
      <Link className="btn" to="/adminmain">Back to home</Link>
      <img src="./assets/img/logo1.png" className="img-fluid py-5" alt="Page Not Found" height={250} width={300}/>
      <div className="credits"> 
        Designed by <a href="https://bootstrapmade.com/">Ammar Sami</a>
      </div>
    </section>
  </div>
</main>{/* End #main */}

    </>
  )
}

export default Error
