import React from 'react'

function Flightdash() {
  return (
    <div>
    <main id="main" className="main">
     <h3 className='display-5 fw-semibold'>Flights</h3>
    </main>
 </div>
  )
}

export default Flightdash
