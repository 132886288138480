import React from 'react'

function Packagedash() {
  return (
    <div>
       <main id="main" className="main">
        <h3 className='display-5 fw-semibold'>Packages</h3>
       </main>
    </div>
  )
}

export default Packagedash
