import React from 'react'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Maindash from '../components/Maindash'
import Footer from '../components/Footer'

function Main() {
  return (
    <div>
      <Header/>
      <Sidebar/>
      <Maindash/>
      <Footer/>
    </div>
  )
}

export default Main
